import Domain from "../domain";
export default {
  methods: {
    getReviewList: async function(condition) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "portal/product/reviewed/list?reviewed="+condition,
          Domain.APP_BUYER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
