<template>
  <div>
    <div
      id="table-content"
      class="col-lg-12 col-md-12 col-sm-12 col-xs-12 tobereview"
      style="padding-left: 0px; padding-right: 0px;"
    >
        <table
          style="overflow-y: auto; margin-bottom: 0px;width: 100%;"
          :class="{table:  toBeRviewArray.length != 0}"
        >
          <tbody>
            <tr v-if="toBeRviewArray.length === 0">
              <td colspan="7" class="text-center norec col-md-12">
                No Records Found!
              </td>
            </tr>
            <tr v-for="(review, index) in toBeRviewArray" :key="index">
              <td  class="text-left"></td>
              <td  class="text-left" style="min-width: 100px;">
                <img
                  style="cursor: pointer;"
                  class="img-thumbnail"
                  width="80px"
                  :src="review.image.image"
                  @click="viewProduct(review)"
                />
              </td>
              <td class="text-left" style="vertical-align: middle;">
                <div>
                  <b>{{
                    review.order_env_number !== undefined
                      ? "Order: " + review.order_env_number
                      : ""
                  }}</b>
                </div>
              </td>
              <td class="text-left" style="vertical-align: middle;">
                <p>
                  <b
                    >Rs.
                    {{
                      review.item_total_price !== undefined
                        ? review.item_total_price
                        : ""
                    }}</b
                  >
                </p>
              </td>
              <td  class="text-left" style="vertical-align: middle;">
                <p>
                  <b
                    >Quantity :
                    {{
                      review.qty !== undefined
                        ? review.qty
                        : ""
                    }}</b
                  >
                </p>
              </td>
              <td class="text-left" style="vertical-align: middle;">
                <b-button
                  style="background: rgb(77, 161, 48);
                  color: white;"
                  @click="rateModal(review)"
                  variant="outline-secondary"
                >
                  Please rate our service
                  <li class="fa fa-smile-o"></li>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <div v-if="toBeRviewArray.length !== 0" class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            ref="paginationCurrentPage3"
            v-model="currentPage1"
            :per-page="pageSize"
            :total-rows="rowcount1"
          ></b-pagination>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      style="padding-top: 3%;"
      class="modal fade"
      id="ratingModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ratingModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ratingModalLongTitle">
              Rate Your Order
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top:-20px;"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <h4>Merchant Communication</h4>
                <CommonRatingStars
                  v-bind:type="'Main'"
                  v-bind:rating="Math.floor(ratingSellCom)"
                  @clicked="onClickRatingSellCom"
                />
                <h4>Delivery satisfaction</h4>
                <CommonRatingStars
                  v-bind:type="'Main'"
                  v-bind:rating="Math.floor(ratingShipTime)"
                  @clicked="onClickRatingShipTime"
                />
                <h4>Accurate product description</h4>
                <CommonRatingStars
                  v-bind:type="'Main'"
                  v-bind:rating="Math.floor(ratingProdQual)"
                  @clicked="onClickRatingProdQual"
                />
                <h4>Willingness to repurchase</h4>
                <CommonRatingStars
                  v-bind:type="'Main'"
                  v-bind:rating="Math.floor(ratingWillingness)"
                  @clicked="onClickratingWillingness"
                />
              </div>
              <div class="col-md-6">
                <label>Comment</label>
                <textarea
                  v-model="ratingComment"
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="8"
                  style="height: auto !important;"
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-5" v-for="(item, index) in image" :key="index">
                <div class="row col-md-12">
                  <img data-index="1"  :src="item"  class="img thumbnail">
                </div> 
                <div class="row">
                  <button class="btn btn-sm btn-danger" @click="clearImage(index)"><li class="fa fa-close"></li></button>
                </div>
              </div>
              <div class="col-md-5" v-if="isLoading"> 
                <div class="row col-md-12">
                  <img data-index="1"  src="assets/image/product/loading.gif"  class="img thumbnail">
                </div> 
              </div>
              <div class="col-md-12">
                <div class="form-group">        
                  <label>Upload Image</label>    
                  <input type="file" class="inputfile" id="embedpollfileinput" @change="getFile" :disabled="uploadDisable">
              </div>
            </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="submitRating()"
                style="background: #5779ae; border: none; color: white;"
              >
                Submit Rating
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="productModalCenter" tabindex="-1" role="dialog" aria-labelledby="productModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
                <h5 class="modal-title" id="productModalLongTitle">Product Quick View</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-top:-20px;">
                <span style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body"> 
                <ProductModalContent v-bind:pid="product_id" />
            </div>
            <div class="modal-footer">
                <button type="button" style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;" class="btn" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
            </div>
        </div>
    </div> 
  </div>
</template>
<script>
import CommonRatingStars from "@/components/common/commonRatingStars.vue";
import mixinOrder from "@/mixins/buyer/APIOrder";
import mixinProduct from "@/mixins/buyer/APIProduct";
import ProductModalContent from '@/components/product/product_modal.vue';
export default {
  props: {
    toBeRviewArray: {
      type: Array,
      default: null,
    },
    currentPage1: {
      type: Number,
      default: false,
    },
    pageSize: {
      type: Number,
      default: false,
    },
    rowcount1: {
      type: Number,
      default: null,
    },
  },
  mixins: [mixinOrder, mixinProduct],
  components: {
    CommonRatingStars,
    ProductModalContent
  },
  data() {
    return {
      ratingSellCom: 0,
      ratingShipTime: 0,
      ratingProdQual: 0,
      ratingWillingness: 0,
      ratingComment: "",
      product_id: "",
      conditionCp: 1,
      selectedOrder: {},
      fileUploadFormData: new FormData(),
      image: [],
      uploadDisable: false,
      isLoading: false,
      norating: false,
      nocomment: false,
    };
  },
  watch: {
    currentPage1: function(val) {
      if(val) {
          this.$emit("paginationHandler1", val, "To Be Reviewd");
      }
      // immediate: true,
      // deep: true,
    },
  },
  beforemounted() {
    if (this.$refs.paginationCurrentPage3) {
      this.paginationStyleFunction(
        this.$refs.paginationCurrentPage3.$refs.ul.children
      );
    }
  },
  methods: {
    paginationStyleFunction(array) {
      if (array === "") {
        array = this.$refs.paginationCurrentPage3.$refs.ul.children;
      }
      if (this.conditionCp !== this.currentPage1) {
        for (let x = 0; x < array.length; x++) {
          if (array[x].innerText === this.conditionCp.toString()) {
            array[x].children[0].style.border = "";
            array[x].children[0].style.background = "";
            array[x].children[0].style.color = "black";
          }
        }
        this.conditionCp = this.currentPage1;
      }
      for (let x = 0; x < array.length; x++) {
        if (array[x].innerText === this.currentPage1.toString()) {
          array[x].children[0].style.border = "2px solid #ffd338";
          array[x].children[0].style.background = "#ffd338";
          array[x].children[0].style.color = "black";
        }
      }
    },
    rateModal(payload) {
      this.selectedOrder = payload;
      this.ratingSellCom = 0;
      this.ratingShipTime = 0;
      this.ratingProdQual = 0;
      this.ratingComment = "";
      $("#ratingModalCenter").modal("show");
    },
    onClickRatingSellCom: function(val, type) {
      this.ratingSellCom = val;
    },
    onClickRatingShipTime: function(val, type) {
      this.ratingShipTime = val;
    },
    onClickRatingProdQual: function(val, type) {
      this.ratingProdQual = val;
    },
    onClickratingWillingness: function(val, type) {
      this.ratingWillingness = val;
    },
    viewProduct: function (obj) {
      this.product_id = obj.product_id;
      $('#productModalCenter').modal('show');
    },
    submitRating: async function() {
      try {
        let norating = false;
        let nocomment = false;
        if (
          this.ratingSellCom == 0 &&
          this.ratingShipTime == 0 &&
          this.ratingProdQual == 0 &&
          this.ratingWillingness == 0
        ) {
          norating = true;
        } else {
          norating = false;
        }

        if (this.ratingComment === "" && !this.image[0] == null) {
          nocomment = true;
        } else {
          nocomment = false;
        }

        if (nocomment || norating) {
          this.$swal.fire({
            position: "center",
            type: "error",
            title: "Please Comment or Rate!",
            showConfirmButton: false,
            timer: 1500,
          });
          return;
        } else {
          let obj = {
            product:this.selectedOrder.product_id,
            seller_rating: this.ratingSellCom,
            shipping_rating: this.ratingShipTime,
            product_quality_rating: this.ratingProdQual,
            willingness_rating: this.ratingWillingness,
            comment: this.ratingComment,
            images: this.image,
            order_id: this.selectedOrder.order_id,
            order_line_id: this.selectedOrder._id,
            thumbs_up: false,
          };
          let response = await this.postRatingReview(obj);
          $("#ratingModalCenter").modal("hide");
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Thank You!",
            showConfirmButton: false,
            timer: 1500,
          });
          this.$parent.tabClickEvent("To Be Reviewd");
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          position: "top-right",
          type: "error",
          title: error.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getFile: async function(eventf) {
      this.isLoading = true;
      await this.fileUploadFormData.append("image", eventf.target.files[0]);

      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          // self.image = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }

      try {
        let formData = await this.fileUploadFormData;
        let response = await self.putReviewImage(formData); 
        this.image.push(response.image);
        this.isLoading = false;
        this.fileUploadFormData = new FormData();
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    clearImage(index){ 
      this.image.splice(index, 1);
      document.getElementById("embedpollfileinput").value = "";
      this.fileUploadFormData = new FormData();
    },
  },
};
</script>
<style scoped>

.tobereview{
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}

#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.norec{
  width: 100%;
  padding-top: 15px;
  text-align: center;
}

.pagination li button {
  padding: 1px 12px;
  min-height: 1.42857143;
}
.pagination li.active > button {
  border: 2px solid #5779ae !important;
  background: #5779ae none repeat scroll 0% 0% !important;
  color: white !important;
}
.pagination > li > span {
  line-height: auto;
}
.pagination > li > span {
  float: none;
}

@media (max-width: 2880px) {
  .tobereview {
    width: 100%;
    /* margin-top: -16px; */
  }
}
@media (max-width: 1440px) {
  .tobereview {
    /* width: 109.4%; */
    /* margin-top: -16px; */
  }
}
@media screen and (max-width: 1348px) {
  #table-content {
    /* overflow-x: scroll; */
    max-width: 1000px !important;
  }
  table {
    width: 960px !important;
  }
}
@media (max-width: 1280px) {
  .tobereview {
    width: 103.5%;
    /* margin-top: -16px; */
  }
}
@media (max-width: 768px) {
  .tobereview {
    width: 105%;
    /* margin-top: -16px; */
  }
}
@media (max-width: 375px) {
  .tobereview {
    width: 112%;
    /* margin-top: -16px; */
  }
}
@media (max-width: 425px) {
  .norec {
    text-align: left;
  }
}
</style>
