<template>
  <div style=""> 
    <div class="col-sm-12" style="padding-right: 0px; padding-top: 10px;">
      <div class="col-sm-12" style="padding-left:0px; border: 0px solid #ddd; padding: 10px; background: white;">
        <ul class="nav nav-tabs">
            <li @click="tabClickEvent('To Be Reviewd')" class="active">
              <a data-toggle="tab" href="#tobereviewd">
                To Be Reviewed
              </a>
            </li >
            <li @click="tabClickEvent('Reviewd')">
              <a data-toggle="tab" href="#reviewd">
                Reviewed
              </a>
            </li>
        </ul>
        <div class="tab-content" style="display: flex; flex: 1; overflow-x: hidden;">
            <div id="tobereviewd" style="width: 100%; overflow-x: scroll;" class="classWell tab-pane fade in active">
                <to-be-reviewd @paginationHandler1="paginationHandler1" :currentPage1="currentPage1" :pageSize="pageSize" :rowcount1="rowcount1" :toBeRviewArray="toBeRviewArray" />
            </div>
            <div id="reviewd" class="classWell tab-pane fade" style="width: 100%;  overflow-x: scroll;">
                <reviewd-list @paginationHandler="paginationHandler" :currentPage="currentPage" :pageSize="pageSize" :rowcount="rowcount" :reviewArray="reviewArray" />
            </div>
        </div> 
      </div>
    </div>
  </div>
</template>
<script>
import ReviewdList from '@/components/buyer/ReviewList/ReviewdList.vue'
import ToBeReviewd from '@/components/buyer/ReviewList/ToBeReviewd.vue'
import APIReview from "@/mixins/buyer/APIReview";
export default {
  mixins: [APIReview],
  components: {
    "reviewd-list": ReviewdList,
    "to-be-reviewd": ToBeReviewd
  },
   data() {
    return {
      reviewArray : [],
      toBeRviewArray : [],
      currentPage: 1,
      currentPage1: 1,
      pageSize: 4,
      rowcount: 0,
      rowcount1: 0,
      sliceValueReview: "",
      sliceValueToBeReview: ""
    }
  },
  created() {
    if (this.currentPage) {
      this.tabClickEvent("To Be Reviewd");
    }
  },
  methods: {
    tabClickEvent: async function(str) {
      try {
        if (str === "To Be Reviewd") {
          this.toBeRviewArray = await this.getReviewList(false);
          this.toBeRviewArray = this.toBeRviewArray.reverse();
          this.rowcount1 = this.toBeRviewArray.length;
          this.sliceValueToBeReview = this.currentPage1 * 4;
          this.sliceValueToBeReview = this.sliceValueToBeReview - 4;
          this.toBeRviewArray = this.toBeRviewArray.slice(this.sliceValueToBeReview, this.sliceValueToBeReview + 4);
        } else if (str === "Reviewd") {
          this.reviewArray = await this.getReviewList(true);
          this.reviewArray = this.reviewArray.reverse();
          this.rowcount = this.reviewArray.length;
          this.sliceValueReview = this.currentPage * 4;
          this.sliceValueReview = this.sliceValueReview - 4;
          this.reviewArray = this.reviewArray.slice(this.sliceValueReview, this.sliceValueReview + 4);
          // for (let x = 0; x < this.reviewArray.length; x++) {
          //   if (this.reviewArray[x].review_avg[0].avg_rate.toString().includes(".")) {
          //     let value = this.reviewArray[x].review_avg[0].avg_rate.toString().split(".");
          //     this.reviewArray[x].reviewAvg = value[0];
          //     if (value[1] === "5" || value[1] > "5") {
          //       this.reviewArray[x].reviewAvg = parseInt(this.reviewArray[x].reviewAvg) + 1;
          //     }
          //   } else {
          //     this.reviewArray[x].reviewAvg = this.reviewArray[x].review_avg[0].avg_rate;
          //   }
          // }
        }
      } catch (error) {
        throw error;
      }
    },
    paginationHandler(val, str) {
      this.currentPage = val;
      this.tabClickEvent(str);
    },
    paginationHandler1(val1, str1) {
      this.currentPage1 = val1;
      this.tabClickEvent(str1);
    }
  }
}
</script>
<style scoped>
.tab-content {
    padding: 15px 20px 28px;
    margin-bottom: 20px;
    z-index: 2;
    /* border: 1px solid #ddd; */
}

.pagination li button {
  padding: 1px 12px;
  min-height: 1.42857143;
}
.pagination li.active > button {
  border: 2px solid #5779ae;
  background: #5779ae none repeat scroll 0% 0%;
  color: white !important;
}
.pagination > li > span {
  line-height: auto;
}
.pagination > li > span {
  float: none;
}
</style>