<template>
  <div>
    <div id="table-content" 
        class="col-lg-12 col-md-12 col-sm-12 col-xs-12 review"
        style="padding-left: 0px;padding-right: 0px;"
      >
        <table
          style="overflow-y: auto; margin-bottom: 0px;width: 100%;"
          :class="{table : reviewArray.length != 0, 'table-striped' : reviewArray.length != 0}"

        >
          <tbody>
            <tr v-if="reviewArray.length === 0">
              <td colspan="7" class="text-center norec col-md-12">No Records Found!</td>
            </tr>
            <tr v-for="(review, index) in reviewArray" :key="index">
              <td class="text-left" style="min-width: 100px;">
                <img
                  style="cursor: pointer;"
                  class="img-thumbnail"
                  width="80px"
                  :src="review.image.image !== undefined ? 
                  review.image.image :''"
                  @click="viewProduct(review)"
                />
              </td>
              <td class="text-left" style="vertical-align: middle;">
                <div>
                  <b>{{ "Order: "+review.order_env_number != undefined ? review.order_env_number : ""}}</b>
                </div>
              </td>
              <td class="text-left" style="vertical-align: middle;">
                <div>
                  <b>
                    {{ review.qty !== undefined ? review.qty: ""}}
                    {{ review.qty>1? " Items": " Item"}}
                  </b>
                </div>
              </td>
              <td class="text-left" style="vertical-align: middle;">
                <div>
                  <b>{{ review.item_total_price !== undefined ? "Rs: "+review.item_total_price: ""}}</b>
                </div>
              </td>
              <td class="text-left" style="vertical-align: middle; width: auto;">
                <button
                  type="button"
                  class="btn btn-sm add-buy-btn"
                  data-toggle="collapse"
                  :data-target="'#demo_'+ index"
                >Comments</button>
                <div :id="'demo_'+ index" class="collapse" style="width: auto; padding-top: 10px;">
                  <span>
                    <p>
                      <b>{{ review.review != undefined ? review.review.comment != undefined ? review.review.comment :"" : ""}}</b>
                      <br />
                      {{ formatDate(review.review.created_date != undefined ? review.review.created_date :"") }}
                    </p>
                  </span>
                  <img
                    v-if="review.review.images.length !== 0"
                    class="img-thumbnail"
                    width="80px"
                    :src="review.review.images.length !== 0 ? 
                    review.review.images[0] :''"
                  />
                </div>
              </td>
              <td class="text-left" style="vertical-align: middle; min-width: auto;">
                <div class="col-md-12">
                  <div>
                    <label
                      style="font-size: 15px;"
                    >{{ review.review.rate!= undefined ?(review.review.rate).toFixed(0) :"" }}</label>
                    <label>/ 5</label>
                  </div>
                  <span v-if="review.review.rate!=undefined">
                    <i
                      :style="(review.review.rate).toFixed(0) >= 1 ? 'color: #fec42d;' : 'color: #bbb;'"
                      style="font-size:16px;"
                      class="fa fa-star"
                    ></i>
                  </span>&nbsp;
                  <span v-if="review.review.rate!=undefined">
                    <i
                      :style="(review.review.rate).toFixed(0) >= 2 ? 'color: #fec42d;' : 'color: #bbb;'"
                      style="font-size:16px;"
                      class="fa fa-star"
                    ></i>
                  </span>&nbsp;
                  <span v-if="review.review.rate!=undefined">
                    <i
                      :style="(review.review.rate).toFixed(0) >= 3 ? 'color: #fec42d;' : 'color: #bbb;'"
                      style="font-size:16px;"
                      class="fa fa-star"
                    ></i>
                  </span>&nbsp;
                  <span v-if="review.review.rate!=undefined">
                    <i
                      :style="(review.review.rate).toFixed(0) >= 4 ? 'color: #fec42d;' : 'color: #bbb;'"
                      style="font-size:16px;"
                      class="fa fa-star"
                    ></i>
                  </span>&nbsp;
                  <span v-if="review.review.rate!=undefined">
                    <i
                      :style="(review.review.rate).toFixed(0) >= 5 ? 'color: #fec42d;' : 'color: #bbb;'"
                      style="font-size:16px;"
                      class="fa fa-star"
                    ></i>
                  </span>&nbsp;
                </div>
              </td>
            </tr>
          </tbody>
        </table>
    </div>
    <div v-if="reviewArray.length !== 0" class="row text-right">
      <div class="col-sm-12">
        <div class="mt-3">
          <b-pagination
            ref="paginationCurrentPage4"
            v-model="currentPage"
            :per-page="pageSize"
            :total-rows="rowcount"
          ></b-pagination>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="productModalCenter1" tabindex="-1" role="dialog" aria-labelledby="productModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
                <h5 class="modal-title" id="productModalLongTitle">
                  Product Quick View
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style="margin-top: -20px"
                >
                  <span style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body"> 
                <ProductModalContent v-bind:pid="product_id" />
            </div>
            <div class="modal-footer">
                <button type="button" style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;" class="btn" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
            </div>
        </div>
    </div> 
  </div>
</template>
<script>
import ProductModalContent from '@/components/product/product_modal.vue';
export default {
  props: {
    reviewArray: {
      type: Array,
      default: null
    },
    currentPage: {
      type: Number,
      default: false
    },
    pageSize: {
      type: Number,
      default: false
    },
    rowcount: {
      type: Number,
      default: null
    }
  },
  components: {
    ProductModalContent
  },
  data() {
    return {
      conditionCp: 1,
      product_id: ""
    };
  },
  watch: {
    currentPage: {
      handler(val) {
        // if (this.$refs.paginationCurrentPage4) {
        //   this.paginationStyleFunction(
        //     this.$refs.paginationCurrentPage4.$refs.ul.children
        //   );
          this.$emit("paginationHandler", val, "Reviewd");
        // }
      },
      immediate: true,
      deep: true
    }
  },
  beforemounted() {
    if (this.$refs.paginationCurrentPage4) {
      this.paginationStyleFunction(
        this.$refs.paginationCurrentPage4.$refs.ul.children
      );
    }
  },
  methods: {
    paginationStyleFunction(array) {
      if (array === "") {
        array = this.$refs.paginationCurrentPage4.$refs.ul.children;
      }
      if (this.conditionCp !== this.currentPage) {
        for (let x = 0; x < array.length; x++) {
          if (array[x].innerText === this.conditionCp.toString()) {
            array[x].children[0].style.border = "";
            array[x].children[0].style.background = "";
            array[x].children[0].style.color = "black";
          }
        }
        this.conditionCp = this.currentPage;
      }
      for (let x = 0; x < array.length; x++) {
        if (array[x].innerText === this.currentPage.toString()) {
          array[x].children[0].style.border = "2px solid #ffd338";
          array[x].children[0].style.background = "#ffd338";
          array[x].children[0].style.color = "black";
        }
      }
    },
    viewProduct: function (obj) {
      this.product_id = obj.product_id;
      $('#productModalCenter1').modal('show');
    },
    formatDate(date) {
      var myDate = new Date(date);
      var myDate_string = myDate.toISOString();
      var myDate_string = myDate_string.replace("T", " ");
      var myDate_string = myDate_string.substring(0, myDate_string.length - 5);
      return myDate_string;
    }
  }
};
</script>
<style scoped>
.norec{
  width: 100%;
  padding-top: 15px;
  text-align: center;
}
#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.pagination li button {
  padding: 1px 12px;
  min-height: 1.42857143;
}
.pagination li.active > button {
  border: 2px solid #5779ae !important;
  background: #5779ae none repeat scroll 0% 0% !important;
  color: white !important;
}
.pagination > li > span {
  line-height: auto;
}
.pagination > li > span {
  float: none;
}
@media (max-width: 2880px) {
  .review {
    width: 100%;
    /* margin-top: -16px; */
  }
}
@media (max-width: 1440px) {
  .review {
    width: 109.4%;
    /* margin-top: -16px; */
  }
}
@media screen and (max-width: 1348px) {
  #table-content {
    max-width: 960px !important;
  }
  table {
    width: 960px !important;
  }
}
@media (max-width: 1280px) {
  .review {
    width: 103.5%;
    /* margin-top: -16px; */
  }
}
@media (max-width: 768px) {
  .review {
    width: 105%;
    /* margin-top: -16px; */
  }
}
@media (max-width: 375px) {
  .review {
    width: 112%;
    /* margin-top: -16px; */
  }
}

.add-buy-btn {
  background: #ffd338 !important;
  color: #000000 !important;
  border: none !important;
  margin-right: 10px;
}

.add-buy-btn:hover {
  color: #ffffff !important;
}
</style>